/**
 * @module utils
 * @namespace WGCM
 */
(function(utils) {

    var arrayPrototype = Array.prototype,
        objectPrototype = Object.prototype,
        nativeForEach = arrayPrototype.forEach,
        nativeSome = arrayPrototype.some,
        slice = arrayPrototype.slice,
        hasOwnProp = objectPrototype.hasOwnProperty,
        encode = window.encodeURIComponent;

    /**
     * @method getFormattedDateWithOffset
     * @param {any} obj
     * @return {Array}
     */
    utils.getFormattedDateWithOffset = function ({ days = 0, hours = 0, minutes = 0, seconds = 0 }) {
        const date = new Date();

        date.setDate(date.getDate() + days);
        date.setHours(date.getHours() + hours);
        date.setMinutes(date.getMinutes() + minutes);
        date.setSeconds(date.getSeconds() + seconds);

        return date.toUTCString();
    }
    /**
     * @method useLocalStorage
     * @param {any} obj
     * @return {Array}
    */
    utils.useLocalStorage = function ({ key, initialValue, storageCacheLifeTime }) {
        const getInitialValue = () => {
            try {
                const storedData = localStorage.getItem(key) || '{}';
                const parsedLocalStorage = JSON.parse(storedData);

                //Check if the cached data has expired
                if (parsedLocalStorage.expires && new Date().getTime() > new Date(parsedLocalStorage.expires).getTime()) {
                    localStorage.removeItem(key);

                    return initialValue;
                }

                return !utils.isEmpty(parsedLocalStorage) ? parsedLocalStorage : initialValue;
            } catch (error) {
                console.log('Error while parsing localStorage data', error);

                return initialValue;
            }
        };

        let localStorageValue = getInitialValue();

        const handleUpdateLocalStorageState = (value) => {
            // Set cache expiration time if storageCacheLifeTime is provided
            const cacheExpirationConfig = storageCacheLifeTime
                ? {
                    expires: utils.getFormattedDateWithOffset(storageCacheLifeTime),
                }
                : {};

            localStorageValue = Object.assign({}, value, cacheExpirationConfig);

            try {
                localStorage.setItem(
                    key,
                    JSON.stringify({
                        ...value,
                        ...cacheExpirationConfig,
                    }),
                );
            } catch (e) {
                console.log('Can not store settings in private mode.');
            }
        };

        return [() => localStorageValue, handleUpdateLocalStorageState];
    };

    /**
     * @method arrayFrom
     * @param {any} obj
     * @param {number} from
     * @param {number} to
     * @return {Array}
     */
    utils.arrayFrom = function(obj, from, to) {
        var i, cloned, size, length, start, upTo;

        try {
            // can't be used with DOM elements in IE < 9
            return slice.call(obj, from, to);
        } catch (e) {
            if (utils.isArray(obj)) {
                return slice.call(obj, from, to);
            }

            // For array like object we handle it ourselves.
            cloned = [];
            length = obj.length;

            // Handle negative value for "from"
            start = from || 0;
            start = (start >= 0 ? start : length + start);

            // Handle negative value for "to"
            upTo = (to) ? to : length;
            if (to < 0) {
                upTo = length + to;
            }

            // Actual expected size of the slice
            size = upTo - start;

            if (size > 0) {
                cloned = new Array(size);
                for (i = 0; i < size; i++) {
                    cloned[i] = obj[start + i];
                }
            }

            return cloned;
        }
    };

    /**
     * @method debounce
     * @param {Function} func
     * @param {Number} wait
     * @param {Boolean} immediate
     * @return {Function}
     */
    utils.debounce = function(func, wait, immediate) {
        var timeout, args, context, timestamp, result,
            later, now;

        now = (Date.now || function() {
            return (new Date()).getTime();
        });

        later = function() {
            var last = now() - timestamp;

            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;

                if (!immediate) {
                    result = func.apply(context, args);

                    if (!timeout) {
                        context = args = null;
                    }
                }
            }
        };

        return function() {
            var callNow = (immediate && !timeout);

            context = this;
            args = arguments;
            timestamp = now();

            if (!timeout) {
                timeout = setTimeout(later, wait);
            }

            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }

            return result;
        };
    };

    /**
     * @method extend
     * @param {Object} target
     * @param {Object} [obj]*
     * @return {Object}
     */
    utils.extend = function() {
        var args = slice.call(arguments),
            target = args[0] || {},
            length = args.length,
            i, obj, key;

        for (i = 1; i < length; i++) {
            obj = args[i];

            for (key in obj) {
                if (hasOwnProp.call(obj, key) && typeof obj[key] !== 'undefined') {
                    target[key] = obj[key];
                }
            }
        }

        return target;
    };

    /**
     * @method forEach
     * @param {Object} obj
     * @param {Function} callback
     * @param {Object} thisObj
     */
    utils.forEach = function(obj, callback, thisObj) {
        var length,
            key,
            i;

        if (!obj) {
            return;
        }

        if (utils.isArrayLike(obj)) {
            if (nativeForEach) {
                return nativeForEach.call(obj, callback, thisObj);
            }

            for (i = 0, length = obj.length; i < length; i++) {
                callback.call(thisObj, obj[i], i, obj);
            }
        } else {
            for (key in obj) {
                if (hasOwnProp.call(obj, key)) {
                    callback.call(thisObj, obj[key], key, obj);
                }
            }
        }

        return obj;
    };

    /**
     * @method some
     * @param {Array} obj
     * @param {Function} callback
     * @param {Object} thisObj
     */
    utils.some = function(obj, callback, thisObj) {
        var keys = (!utils.isArrayLike(obj) && utils.keys(obj)),
            length = (keys || obj).length,
            currentKey,
            i;

        if (!keys && nativeSome) {
            return nativeSome.call(obj, callback, thisObj);
        }

        for (i = 0; i < length; i++) {
            currentKey = (keys ? keys[i] : i);

            if (hasOwnProp.call(obj, currentKey) && callback.call(thisObj, obj[currentKey], currentKey, obj) === true) {
                return true;
            }
        }

        return false;
    };

    /**
     * @method hasAnyProperty
     * @param {obj} obj
     * @returns {Boolean}
     */
    utils.hasAnyProperty = function(obj) {
        var key;

        for (key in obj) {
            if (hasOwnProp.call(obj, key)) {
                return true;
            }
        }

        return false;
    };

    /**
     * @method inArray
     * @param {Array} array
     * @param {any} obj
     * @returns {Boolean}
     */
    utils.inArray = function(array, obj) {
        var length = array.length,
            i;

        if (!array || !obj) {
            return false;
        }

        for (i = 0; i < length; i++) {
            if (array[i] === obj) {
                return true;
            }
        }

        return false;
    };

    /**
     * @method isArray
     * @param {*} obj
     * @return {Boolean}
     */
    utils.isArray = Array.isArray ? Array.isArray : function(obj) {
        return objectPrototype.toString.call(obj) === '[object Array]';
    };

    /**
     * @method isArrayLike
     * @param {obj/array} collection
     * @return {Boolean}
     */
    utils.isArrayLike = function(collection) {
        var MAX_ARRAY_INDEX = Math.pow(2, 53) - 1,
            length;

        if (collection && collection.length) {
            length = collection.length;
        }

        return (typeof length === 'number' && length >= 0 && length <= MAX_ARRAY_INDEX);
    };

    /**
     * @method keys
     * @param {object} obj
     */
    utils.keys = function(obj) {
        var keys = [],
            key;

        if (!obj || typeof obj !== 'object' || typeof obj === null) {
            return [];
        }

        if (Object.keys) {
            return Object.keys(obj);
        }

        for (key in obj) {
            if (hasOwnProp.call(obj, key)) {
                keys.push(key);
            }
        }

        return keys;
    };

    /**
     * @method formatString
     * @param {String} str
     * @return {String}
     *
     * @example
     * utils.formatString('{0} {2} {1}', 'foo', 'bar', 'baz') // => 'foo baz bar'
     */
    utils.formatString = function(str) {
        var args = slice.call(arguments, 1);

        if (!str) {
            return '';
        }

        return str.replace(/(?:{(\d)})/g, function(match, index) {
            index = parseInt(index, 10);
            return (args[index] !== undefined) ? args[index] : match;
        });
    };

    /**
     * @method toQueryString
     * @param {Object} obj
     * @return {String}
     */
    utils.toQueryString = function(obj) {
        var queryParameters = [],
            key;

        for (key in obj) {
            if (hasOwnProp.call(obj, key)) {
                queryParameters.push(encode(key) + '=' + encode(obj[key]));
            }
        }

        return queryParameters.join('&');
    };

    /**
     * @method addQueryString
     * @param {String} url
     * @param {String} queryString
     * @return {String}
     */
    utils.addQueryString = function(url, queryString) {
        if (queryString) {
            var isQuestionMarkPresent = (url && url.indexOf('?') !== -1),
                isHashMarkPresent = (url && url.indexOf('#') !== -1),
                separator = isQuestionMarkPresent ? '&' : '?';

            if (isHashMarkPresent) {
                url = url.substr(0, url.indexOf('#')) + separator + queryString + url.substr(url.indexOf('#'));
            } else {
                url += separator + queryString;
            }
        }

        return url;
    };

    /**
     * @method isEmpty
     * @param {Object} 
     * @return {Boolean}
     */
    utils.isEmpty = function(obj) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    };

    /**
     * @method escape
     * @param {String} str
     * @return {String}
     */
    utils.escape = (function() {
        var escapeMap = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                '\'': '&#x27;',
                '`': '&#x60;'
            },
            replacer = function(match) {
                return escapeMap[match];
            },
            escapes = [],
            key, regExp;

        for (key in escapeMap) {
            if (hasOwnProp.call(escapeMap, key)) {
                escapes.push(key);
            }
        }

        regExp = new RegExp('(?:' + escapes.join('|') + ')', 'g');

        return function(str) {
            return (typeof str === 'string') ? str.replace(regExp, replacer) : str;
        };

    }());

}(WGCM.utils = {}));
